import { buildSelector, constToSelectOptions } from "../utils/Utils";

export const PortionsModel = (constants) => {
  return {
    name: {
      type: "input",
      validationRules: "required",
      value: "",
      md: 3,
      requestNormalizer: function (data) {
        return data;
      },
      responseNormalizer: (option) => {
        return option;
      },
    },
    size: {
      inputType: "number",
      validationRules: "required|min:0,num",
      value: "",
      md: 2,
      requestNormalizer: function (data) {
        return parseFloat(data);
      },
      responseNormalizer: (option) => {
        return option;
      },
      help:
        "Size of portion (e.g. 56 grams). Must always be in grams/milliliters, even if unit is ounces, liters etc",
    },
    count: {
      inputType: "number",
      validationRules: "required|min:1,num",
      value: 1,
      md: 2,
      requestNormalizer: function (data) {
        return parseFloat(data);
      },
      responseNormalizer: (option) => {
        return option;
      },
      help: "Default portion count (e.g. 2 apples)",
    },
    unit: {
      ...buildSelector(
        constToSelectOptions(constants, "portion_unit"),
        "select"
      ),
      validationRules: "required",
      value: "grams",
      md: 2,
      help: "Portion unit",
    },
    type: {
      ...buildSelector(
        constToSelectOptions(constants, "portion_type"),
        "select"
      ),
      validationRules: "required",
      value: "custom",
      md: 2,
      help:
        "How this portion is represented (e.g. bottle).\nUse 'custom' if no appropriate type can be found",
    },
    popularity: {
      inputType: "number",
      value: "",
      md: 8,
      hidden: true,
      requestNormalizer: function (data) {
        return parseFloat(data);
      },
      responseNormalizer: (option) => {
        return option;
      },
    },
  };
};
