import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import React from "react";
import { CommonFoodRequestModel } from "../../models/CommonFoodRequest";

export default class CommonFoodRequestEdit extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Barcode Request";
    this.url = "/admin/query-requests";
    this.redirectUrl = "/admin/common-food-requests";
    this.skipPrepopulation = true;
  }

  getEntity = () => {
    return CommonFoodRequestModel(this.context.constants);
  };

  render() {
    return React.cloneElement(super.render(), {
      responseData: this.locationState().resource,
    });
  }
}
