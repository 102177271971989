import React, { Component } from "react";
import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { NavLink } from "react-router-dom";
import { BarcodeRequestFilter } from "../../models/BarcodeRequest";
import { API } from "aws-amplify";

export default class BarcodeRequest extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.editPath = "/admin/barcode-requests/edit";
    this.state = {
      key: 0,
    };
  }

  updateProductStatus = (id, status) => {
    return API.put("admin", `/admin/barcode-requests/${id}`, {
      body: { status: status },
    });
  };

  render() {
    let get_const = this.context.get_const;

    return (
      <List
        name="Barcode Request"
        actions={[
          (list, data) => {
            return (
              <NavLink
                to={{
                  pathname: list.props.editPath + "/" + data.barcode,
                  state: { resource: data, barcode: data.barcode },
                }}
                className="btn btn-primary"
              >
                Change Status
              </NavLink>
            );
          },
          (list, data) => {
            if (data.food_id) {
              return (
                <NavLink
                  to={{
                    pathname: `foods/edit/${data.food_id}`,
                    state: { resource: data, barcode: data.barcode },
                  }}
                  className="btn btn-success btn-fill"
                >
                  View
                </NavLink>
              );
            } else {
              return (
                <NavLink
                  to={{
                    pathname: `foods/add`,
                    state: {
                      resource: data,
                      status: "resolved",
                      barcode: data.barcode,
                    },
                    onSuccess: () => {
                      return this.updateProductStatus(data.barcode, "resolved");
                    },
                  }}
                  className="btn btn-success"
                >
                  Create
                </NavLink>
              );
            }
          },
        ]}
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/barcode-requests"
        listApiUrl="/admin/barcode-requests"
        model={[
          { col: "Barcode", row: "barcode" },
          {
            col: "Name",
            row: "food",
            normalizer: (data) => {
              let product = data.food || {};
              if (product.brand || product.name) {
                return (
                  <>
                    <b>{`${product.brand || ""}`}</b> -
                    <span>{` ${product.name || ""}`}</span>
                  </>
                );
              } else {
                return "N/A";
              }
            },
          },
          { col: "Tries", row: "tries" },
          {
            col: "Status",
            row: "status",
            normalizer: (data) => {
              let colors = {
                resolved: "green",
                added_by_user: "orange",
                spam: "red",
                pending: "black",
              };
              return (
                <p style={{ color: colors[data.status] || "black" }}>
                  {get_const("barcode_request_status", data.status)}
                </p>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={BarcodeRequestFilter(this.context.constants)}
        userHasPermission={this.props.userHasPermission}
      />
    );
  }
}
