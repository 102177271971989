import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import { FoodModel } from "../../models/Food";
import Barcode from "react-barcode";
import { Col, Grid, Row } from "react-bootstrap";
import Card from "../../components/Card/Card";
import "gtin";
import { getFormat, isValid, minify } from "gtin";

export default class FoodAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Food";
    this.url = "/admin/foods";
    this.redirectUrl = "/admin/foods";
  }

  handleSaveFood = () => {
    let onSuccess = this.props.location.onSuccess;
    if (onSuccess !== undefined) {
      onSuccess()
        .then(() => {
          this.props.handleClick(
            `${this.name} successfully created`,
            "success",
            "tr"
          );
          this.props.history.push("/admin/barcode-requests");
        })
        .catch((error) => {
          console.error("Something went wrong", error);
        });
    } else {
      this.onSuccess();
    }
  };

  getBarcode() {
    return this.props.match.params.asd || this.locationState()?.barcode || null;
  }

  getEntity = () => {
    return FoodModel(this.context.constants);
  };

  render() {
    let barcode = this.getBarcode();
    console.log("barcode", barcode);
    let format = "EAN13";

    if (barcode && isValid(barcode)) {
      barcode = minify(barcode);

      let gtinType = getFormat(barcode);

      if (gtinType === "GTIN-8") {
        format = "EAN8";
      } else if (gtinType === "GTIN-12") {
        format = "UPC";
      } else if (gtinType === "GTIN-13") {
        format = "EAN13";
      } else {
        console.warn("Unknown barcode format", gtinType);
      }
    }

    return (
      <div className="content">
        {barcode && (
          <Grid fluid>
            <Row>
              <Col md={6}>
                <Card
                  title={"Barcode"}
                  content={<Barcode value={barcode} format={format} />}
                />
              </Col>
            </Row>
          </Grid>
        )}
        {React.cloneElement(super.render(), {
          md: 12,
          onSuccess: this.handleSaveFood,
        })}
      </div>
    );
  }
}
