import React, { Component } from "react";
import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { NavLink } from "react-router-dom";
import { API } from "aws-amplify";
import { CommonFoodRequestFilter } from "../../models/CommonFoodRequest";

export default class CommonFoodRequest extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.editPath = "/admin/common-food-requests/edit";
    this.state = {
      key: 0,
    };
  }

  updateProductStatus = (id, status) => {
    return API.put("admin", `/admin/query-requests/${id}`, {
      body: { status: status },
    });
  };

  render() {
    let get_const = this.context.get_const;

    return (
      <List
        name="Common Food Request"
        actions={[
          (list, data) => {
            return (
              <NavLink
                to={{
                  pathname: list.props.editPath + "/" + data.id,
                  state: {
                    resource: data,
                  },
                }}
                className="btn btn-primary"
              >
                Change Status
              </NavLink>
            );
          },
        ]}
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/query-requests"
        listApiUrl="/admin/query-requests"
        model={[
          { col: "Barcode", row: "id" },
          { col: "Tries", row: "tries" },
          {
            col: "Status",
            row: "status",
            normalizer: (data) => {
              let colors = {
                resolved: "green",
                added_by_user: "orange",
                spam: "red",
                pending: "black",
              };
              return (
                <p style={{ color: colors[data.status] || "black" }}>
                  {get_const("barcode_request_status", data.status)}
                </p>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={CommonFoodRequestFilter(this.context.constants)}
        userHasPermission={this.props.userHasPermission}
      />
    );
  }
}
