import { buildSelector, constToSelectOptions } from "../utils/Utils";

export const CommonFoodRequestFilter = (constants) => {
  return {
    id: {
      type: "input",
      value: "",
    },
    status: buildSelector(
      constToSelectOptions(constants, "barcode_request_status"),
      "select"
    ),
  };
};

export const CommonFoodRequestModel = (constants) => {
  return {
    status: {
      ...buildSelector(
        constToSelectOptions(constants, "barcode_request_status"),
        "select"
      ),
    },
  };
};
