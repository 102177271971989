import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class EditButton extends Component {
  render() {
    const { editPath, id, ...rest } = this.props;
    if (editPath) {
      return (
        <NavLink
          to={{
            pathname: `${editPath}/${id}`,
            state: rest,
          }}
          className="btn btn-primary"
        >
          Edit
        </NavLink>
      );
    }

    return "";
  }
}

export default EditButton;
