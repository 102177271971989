import defaults from "./defaults";
import Info from "./controllers/Info";
import BarcodeRequest from "./controllers/BarcodeRequest/BarcodeRequest";
import BarcodeRequestEdit from "./controllers/BarcodeRequest/BarcodeRequestEdit";
import Food from "./controllers/Food/Food";
import FoodAdd from "./controllers/Food/FoodAdd";
import FoodEdit from "./controllers/Food/FoodEdit";
import FoodReport from "./controllers/FoodReport/FoodReport";
import CommonFoodRequest from "./controllers/CommonFoodRequest/CommonFoodRequest";
import CommonFoodRequestEdit from "./controllers/CommonFoodRequest/CommonFoodRequestEdit";

let g = defaults.userGroups;

const dashboardRoutes = [
  // Food
  {
    exact: true,
    path: "/foods",
    name: "Food",
    icon: "pe-7s-piggy",
    component: Food,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/foods/add",
    name: "Add Food",
    component: FoodAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/foods/edit/:id",
    name: "Edit Food",
    component: FoodEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Barcode Requests
  {
    exact: true,
    path: "/barcode-requests",
    name: "Barcode Requests",
    icon: "pe-7s-cloud-upload",
    component: BarcodeRequest,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/barcode-requests/edit/:id",
    name: "Edit Barcode Requests",
    component: BarcodeRequestEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Food Requests
  {
    exact: true,
    path: "/common-food-requests",
    name: "Common Food Requests",
    icon: "pe-7s-drop",
    component: CommonFoodRequest,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    exact: true,
    path: "/common-food-requests/edit/:id",
    name: "Edit Common Food Requests",
    component: CommonFoodRequestEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Food reports
  {
    exact: true,
    path: "/food-reports",
    name: "Food Reports",
    icon: "pe-7s-attention",
    component: FoodReport,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/info",
    name: "Info",
    icon: "pe-7s-info",
    component: Info,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer, g.support],
  },
];

export default dashboardRoutes;
