import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import DeleteButton from "../../components/List/DeleteButton";

export default class FoodReport extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.editPath = "/admin/food-reports/edit";
  }

  render() {
    return (
      <List
        name="Food"
        actions={[
          (list, data_) => {
            return (
              <DeleteButton
                id={data_.id}
                handleRemove={list.handleRemove}
                allowedGroups={list.props.allowedDelete}
              />
            );
          },
          (list, data) => {
            return (
              <NavLink
                to={{
                  pathname: `foods/edit/${data.id}`,
                  state: { resource: data, barcode: data.barcode },
                }}
                className="btn btn-primary"
              >
                Edit food
              </NavLink>
            );
          },
        ]}
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/food-reports"
        listApiUrl="/admin/food-reports"
        model={[
          { col: "Food ID", row: "id" },
          { col: "Name", row: "name" },
          {
            col: "Reasons",
            row: "report_reasons",
            normalizer: (data) => {
              return (
                <ol>
                  {(data.report_reasons || []).map((reason, index) => {
                    return (
                      <li key={index}>
                        {this.context.get_const("report_reason", reason)}
                      </li>
                    );
                  })}
                </ol>
              );
            },
          },
          {
            col: "Messages",
            row: "message",
            normalizer: (data) => {
              return (
                <ol>
                  {(data.message || []).map((message, index) => {
                    if (!message) {
                      return undefined;
                    }
                    return (
                      <li key={index}>
                        {message.split(/\r?\n/).map((m) => (
                          <div>{m}</div>
                        ))}
                      </li>
                    );
                  })}
                </ol>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        // filterObject={FoodFilter()}
        userHasPermission={this.props.userHasPermission}
      />
    );
  }
}
