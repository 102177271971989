import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { FoodFilter } from "../../models/Food";
import React, { Component } from "react";
import EditButton from "../../components/List/EditButton";

export default class Food extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/foods/add";
    this.editPath = "/admin/foods/edit";
  }

  render() {
    return (
      <List
        name="Food"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/foods"
        listApiUrl="/admin/foods"
        model={[
          { col: "Id", row: "id" },
          {
            col: "Verified",
            row: "verified",
            normalizer: (data) => {
              if (data.verified) {
                return (
                  <div>
                    <span role="img" aria-label="check">
                      ✅
                    </span>
                  </div>
                );
              }
              return (
                <div>
                  <span role="img" aria-label="stop-sign">
                    🛑
                  </span>
                </div>
              );
            },
          },
          { col: "Name", row: "name" },
          { col: "Brand", row: "brand" },
          { col: "Barcode", row: "barcode" },
          {
            col: "Calories",
            row: "calories",
            normalizer: (data) => {
              if (data.calories === null || data.calories === undefined) {
                return <div color={"red"}>-</div>;
              }
              return `${(data.calories / 1000).toFixed(1)}K`;
            },
          },
        ]}
        actions={[
          (list, data_) => {
            return (
              <EditButton
                editPath={list.props.editPath}
                barcode={data_.barcode}
                id={data_.id}
              />
            );
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={FoodFilter(this.context.constants)}
        userHasPermission={this.props.userHasPermission}
      />
    );
  }
}
