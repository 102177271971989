import { buildSelector, constToSelectOptions } from "../utils/Utils";
import { PortionsModel } from "./Portion";

export const FoodFilter = (constants) => {
  return {
    id: {
      type: "input",
      value: "",
    },
    search: {
      type: "input",
      value: "",
    },
    type: buildSelector(constToSelectOptions(constants, "food_type"), "select"),
  };
};

export const FoodModel = (constants) => {
  return {
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    brand: {
      type: "input",
      validationRules: "",
      value: "",
      requestNormalizer: (data) => {
        return data || null;
      },
    },
    barcode: {
      type: "input",
      validationRules: [{ regex: "^\\d{8,14}$" }],
      value: "",
    },
    unit: {
      ...buildSelector(
        constToSelectOptions(constants, "measure_unit"),
        "select"
      ),
      help: "Main product unit of measure",
    },
    alcohol: {
      value: "0",
      inputType: "number",
      validationRules: "min:0,num|max:100,num",
      requestNormalizer: (data) => {
        return parseFloat(data);
      },
      help: "Optional, measured in percentage",
    },
    calories: {
      value: "0",
      inputType: "number",
      validationRules: "required|min:0,num",
      responseNormalizer: (data) => {
        return data / 1000;
      },
      requestNormalizer: (data) => {
        return parseFloat(data) * 1000;
      },
      help: "Measured in kcal per 1 gram/ml",
    },
    ...Object.fromEntries(
      [
        "protein",
        "fat",
        "trans_fat",
        "saturated_fat",
        "carbohydrates",
        "sugar",
        "added_sugar",
        "cholesterol",
        "fiber",
        "potassium",
        "sodium",
      ].map((x) => [
        x,
        {
          value: "0",
          inputType: "number",
          validationRules: "required|min:0,num",
          requestNormalizer: (data) => {
            return parseFloat(data);
          },
          help: "Measured in grams per 1 gram/ml",
        },
      ])
    ),
    portions: {
      type: "table_collection",
      value: [],
      prototype: PortionsModel(constants),
      onChangeEvent: "handleCollection",
      validationRules: "",
      md: 12,
      requestNormalizer: function (data) {
        return data;
      },
    },
  };
};
