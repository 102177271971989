import { BarcodeRequestModel } from "../../models/BarcodeRequest";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import React from "react";

export default class BarcodeRequestEdit extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Barcode Request";
    this.url = "/admin/barcode-requests";
    this.skipPrepopulation = true;
  }

  getEntity = () => {
    return BarcodeRequestModel(this.context.constants);
  };

  render() {
    return React.cloneElement(super.render(), {
      responseData: this.locationState().resource,
    });
  }
}
